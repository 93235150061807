<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack">人才库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{userName}} -- 工作经历</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox" style="margin-left:20px">
              <!--              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>-->
              <el-button style="margin-left:20px" class="bgc-bv" round @click="showUpdateData()">新增工作经历</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="table"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="公司名称"
                  align="left"
                  prop="companyName"
                  show-overflow-tooltip
                  min-width="160"
              />
              <el-table-column
                  label="职位名称"
                  align="left"
                  prop="positionName"
                  show-overflow-tooltip
                  min-width="120"
              />
              <el-table-column
                  label="任职时间"
                  align="left"
                  show-overflow-tooltip
                  min-width="160"
              >
                <template slot-scope="{ row }">
                  {{row.entryTime}} ~ {{row.resignationTime}}
                </template>
              </el-table-column>
              <el-table-column
                  label="工作描述"
                  align="left"
                  prop="description"
                  show-overflow-tooltip
                  min-width="160"
              />
              <el-table-column
                  label="离职原因"
                  align="left"
                  prop="reasons"
                  show-overflow-tooltip
                  min-width="160"
              />
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="{ row }">
                  <el-button type="text"
                             style="padding:0px 5px"
                             size="mini"
                             @click="showUpdateData(row)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="deleteData(row.workExperienceId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div>
<!--        <h3 style="padding: 0 0 20px 0;">沟通记录</h3>-->
        <el-form
            :model="dataForm"
            ref="dataForm"
            :rules="dataRules"
            label-width="150px"
        >
          <el-form-item label="公司名称：" prop="companyName">
            <el-input v-model="dataForm.companyName" size="small" placeholder="请输入公司名称"></el-input>
          </el-form-item>
          <el-form-item label="职位名称：" prop="positionName">
            <el-input v-model="dataForm.positionName" size="small" placeholder="请输入职位名称" @focus="inputpositio"></el-input>
          </el-form-item>
          <el-form-item label="入职时间：" prop="entryTime">
            <el-date-picker
                v-model="dataForm.entryTime"
                type="date"
                placeholder="选择入职时间"
                value="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
                size="small"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="工作描述：" prop="description">
            <el-input
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10}"
                v-model="dataForm.description"
                placeholder="请输入工作描述"
                maxlength="2000"
                show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="离职原因：" prop="reasons">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                v-model="dataForm.reasons"
                placeholder="请输入离职原因"
                maxlength="200"
                show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="离职时间：" prop="resignationTime">
            <el-date-picker
                v-model="dataForm.resignationTime"
                type="date"
                placeholder="选择离职时间"
                value="yyyy/MM/dd"
                value-format="yyyy/MM/dd"
                size="small"
            ></el-date-picker>
          </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 职位名称 -->
    <el-dialog title="职位名称" :visible.sync="previewLoding2" width="40%" top="5%" center>
      <div class="zjzw">
        <div>
          <div
              :class="previewLodingData_1_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_1"
              :key="index"
              @click="selectpreview(1,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_2_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_2"
              :key="index"
              @click="selectpreview(2,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
              :class="previewLodingData_3_positionTypeId == item.positionTypeId ? 'ts_1': ''"
              v-for="(item,index) in previewLodingData_3"
              :key="index"
              @click="selectpreview(3,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workExperience",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      talentId: this.$route.query.talentId,
      userName: this.$route.query.userName,

      areaList: [], // 期望城市List
      salaryRequireList: [], // 薪资要求List

      dialogTitle: '',
      dialogVisible: false, //新增编辑弹窗开关

      dataForm: {
        companyName: "",
        positionId: "",
        positionName: "",
        entryTime: "",
        description: "",
        reasons: "",
        resignationTime: "",
      },

      // itemRoleList: [], // 项目角色List

      dataRules: {
        companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      },


      // 职位名称 - 弹框
      previewLoding2: false,
      // 职位名称 - 一级类数据 - 也是所有数据
      previewLodingData_1: [],
      // 职位名称 - 二级类数据
      previewLodingData_2: [],
      // 职位名称 - 三级类数据
      previewLodingData_3: [],
      // 职位名称 - 一级类数据 - 当前id
      previewLodingData_1_positionTypeId: "",
      // 职位名称 - 二级类数据 - 当前id
      previewLodingData_2_positionTypeId: "",
      // 职位名称 - 三级类数据 - 当前id
      previewLodingData_3_positionTypeId: "",
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    this.getPreviewLoding2();
    // this.getDropDownData()
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 all- 下拉数据
    // getDropDownData() {
    //  // 薪资要求list
    //   const salaryRequireList = this.$setDictionary(
    //       "MINI_JOB_SERVICE_SALARY",
    //       "list"
    //   );
    //   for (const key in salaryRequireList) {
    //     this.salaryRequireList.push({
    //       value: key,
    //       label: salaryRequireList[key],
    //     });
    //   }
    // },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId
      };
      this.doFetch({
        url: "/hr/talent/info/workExperienceLogPage",
        params,
        pageNum
      });
    },
    // 打开新增&编辑
    showUpdateData(row){
      this.dialogVisible = true
      let str = '工作经历'
      this.$nextTick(()=>{
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          companyName: "",
          positionId: "",
          positionName: "",
          entryTime: "",
          description: "",
          reasons: "",
          resignationTime: "",
        }

        if(row){
          this.dialogTitle = '编辑' + str
          this.dataForm = {
            ...row
          }
          // this.dataForm.itemTime = [ row.beginTime.substring(0,10), row.endTime.substring(0,10) ]
          this.dataForm.workExperienceId = row.workExperienceId
        }else{
          this.dialogTitle = '新增' + str
          this.dataForm.workExperienceId = ""
        }
      })
    },
    // 关闭新增&编辑
    handleClose() {
      this.dialogVisible = false
    },
    // 弹层保存数据
    saveData(){
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let params = {
            talentId: this.talentId,
            companyName: this.dataForm.companyName,
          }
          if(this.dataForm.workExperienceId != ""){
            params.workExperienceId = this.dataForm.workExperienceId
          }
          if(this.dataForm.positionId){
            params.positionId = this.dataForm.positionId
            params.positionName = this.dataForm.positionName
          }
          if(this.dataForm.entryTime){
            params.entryTime = this.dataForm.entryTime
          }
          if(this.dataForm.description){
            params.description = this.dataForm.description
          }
          if(this.dataForm.reasons){
            params.reasons = this.dataForm.reasons
          }
          if(this.dataForm.resignationTime){
            params.resignationTime = this.dataForm.resignationTime
          }
          console.log(params)
          this.$post(
              "/hr/talent/info/saveOrUpdateWorkExperience",
              params
          ).then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.dialogVisible = false
              this.getData(-1);
            }
          });
        }
      })
    },
    // 删除列表数据
    deleteData(id){
      let str = '工作经历'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let params = {
              // talentId: this.talentId,
              workExperienceId: id
            }
            console.log(params)
            this.$post(
                "/hr/talent/info/removeWorkExperienceLog",
                params
            ).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getData(-1);
              }
            });
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 职位名称
    inputpositio(){
      this.previewLoding2 = true;
      // 职位名称 - 二级类数据
      this.previewLodingData_2= []
      // 职位名称 - 三级类数据
      this.previewLodingData_3= []
      // 职位名称 - 一级类数据 - 当前id
      this.previewLodingData_1_positionTypeId= ""
      // 职位名称 - 二级类数据 - 当前id
      this.previewLodingData_2_positionTypeId= ""
      // 职位名称 - 三级类数据 - 当前id
      this.previewLodingData_3_positionTypeId= ""
    },
    // 获取 - 职位名称：但只取当前第一组数据
    getPreviewLoding2() {
      this.$post("/hr/talent/hr-position-type/queryAll")
          .then(res => {
            this.previewLodingData_1 = res.data || [];
          })
          .catch(() => {
            return;
          });
    },
    // 点击 - 职业分类
    selectpreview(type,item,positionTypeId){
      if(type == 1) {
        this.previewLodingData_1_positionTypeId = positionTypeId;
        this.previewLodingData_2 = item.children;
        this.previewLodingData_3 = [];
      }
      if(type == 2) {
        this.previewLodingData_2_positionTypeId = positionTypeId;
        this.previewLodingData_3 = item.children;
      }
      if(type == 3) {
        this.previewLodingData_3_positionTypeId = positionTypeId;
        this.dataForm.positionId = positionTypeId;
        console.log(item.positionTypeName)
        this.dataForm.positionName = item.positionTypeName;
        this.previewLoding2 = false;
      }
    },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {}
};
</script>
<style lang="less" scoped>
.el-rate__icon {
  margin-right: 0;
}
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.el-form {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 33.33%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>
